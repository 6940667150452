import React from "react"
import Service from "../../components/Shared/Service"
import checkMark from "../../assets/icons/noun-check-mark.svg"
import coe from "../../assets/icons/noun_Idea AI bot_1812226.svg"
import quality from "../../assets/icons/noun_Check_3974112.svg"
import optimization1 from "../../assets/icons/noun_optimization_2334501.svg"
import { graphql } from "gatsby"

const seoTitle = "Total Quality Management "
const seoDescription =
  "Atyeti's helps organizations to adapt the new mindset of software testing and quality assurance to enable successful digital transformation."
const keyword =
  " Total Quality Management, Cloud Quality Assurance, BOT & AI Quality Assurance, Environment Management, Automated Testing, Service virtualization, Test data management, Security and Performance, agile Automated Testing, Replatforming"
const mainHeading = "Total Quality Management"
const mainPara = `Helping organizations to adapt the new mindset of software testing and quality assurance to enable successful digital transformation.`

const serviceIcons = [
  { id: 1, name: "Cloud Quality Assurance ", icon: checkMark },
  { id: 2, name: " BOT & AI Quality Assurance", icon: checkMark },
  { id: 3, name: "Environment Management", icon: checkMark },
  { id: 4, name: " Automated Testing", icon: checkMark },
  { id: 5, name: "Service virtualization", icon: checkMark },
  { id: 6, name: "Test data management", icon: checkMark },
  {
    id: 7,
    name: " Security  and Performance",
    icon: checkMark,
  },
  { id: 8, name: "Agile", icon: checkMark },
]

const section2Heading = `Our Services`
const section2Para = `Atyeti Testing Center of Excellence delivers results by helping companies optimize application quality and performance.`

const cardItems = [
  {
    id: 1,
    icon: coe,
    heading: `Modern Quality Engineering and Assurance - COE`,
    listItems: (
      <>
        <li>
          Support new technologies, run new functionality, bring products to
          market.
        </li>
        <li>Discover and fix defects.</li>
        <li>
          Best Practices -Standardized testing methodology, best practices,
          automation, metrics and tools.
        </li>
        <li>Pool of available talent - Global delivery.</li>
        <li>
          Flexibility in rapidly meeting the change in customer’s requirements.
        </li>
        <li>Help build a culture of Quality.</li>
      </>
    ),
  },
  {
    id: 2,
    icon: quality,
    heading: `Automation First Approach`,
    listItems: (
      <>
        <li>Improved accuracy and coverage. </li>
        <li>
          Our modern test automation platform compliments DevOps, CI/CD, and
          cloud-oriented automation.
        </li>
        <li>
          We use specialized tools to execute test cases and generate results.
        </li>
        <li>Save time, especially in regression testing.</li>
        <li>
          Automated test scripts result in reusability- saving time and money.
        </li>
      </>
    ),
  },
  {
    id: 3,
    icon: optimization1,
    heading: `Shift Left & Change Management`,
    listItems: (
      <>
        <li>
          In the Agile world, reduce the time to deliver and improve the quality
          of each release.
        </li>
        <li>
          Shift Leftwill lower the cost of testing & development by identifying
          defects earlier and push quality upstream.
        </li>
        <li>Fail Early- Test Earlier, Test Often with ShiftLeft.</li>
      </>
    ),
  },
]

const ServiceDetails = ({ data }) => (
  <Service
    seoTitle={seoTitle}
    mainHeading={mainHeading}
    mainPara={mainPara}
    serviceIcons={serviceIcons}
    // section2Heading={section2Heading}
    cardItems={cardItems}
    section2Para={section2Para}
    seoDescription={seoDescription}
    caseItems={data}
    keyword={keyword}
    className="total-quality-management"

  />
)
export const query = graphql`
  query TotalQualityCaseQuery {
    casestudyJson(mainheading: { eq: "total-quality-management" }) {
      data {
        heading
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        para
        slug
        subheading
      }
      bannerimage {
        heading
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
export default ServiceDetails
